<template>
  <div>
    <h1>Briefing manche {{ currentRound.round_number }}</h1>

    <errors-viewer :errors="currentRound.configurationErrors"></errors-viewer>

    <collapse-panel lazy title="Présentation du staff" v-model="showStaffPanel" :visible="currentRound.round_number == 1">
      <card-list-template >
        <b-card class="small" v-for="member in currentCompetition.STAFF_MEMBERS" :key="member.id">
                <div style="display:flex; justify-content: space-between;">
                    <div>
                        <b class="staff-member-name">{{ member.firstname }} {{ member.lastname }}</b>&nbsp;<federal-number :is-federal="currentCompetition.isFederal" v-model="member.LICENSEE"></federal-number>
                    </div>
                </div>
                <div>
                    <b-badge class="capability capability-judge" v-if="member.CAPABILITIES.isJudge">Juge</b-badge>
                    <b-badge class="capability capability-judge capability-shadow" v-if="member.CAPABILITIES.isJudgeShadow">Juge ombre</b-badge>
                    <b-badge class="capability capability-field-director" v-if="member.CAPABILITIES.isFieldDirector">Directeur de terrain</b-badge>
                    <b-badge class="capability capability-field-director capability-shadow" v-if="member.CAPABILITIES.isFieldDirectorShadow">Directeur de terrain ombre</b-badge>
                    <b-badge class="capability capability-scorer" v-if="member.CAPABILITIES.isScorer">Scoreur</b-badge>
                    <b-badge class="capability capability-scorer capability-shadow" v-if="member.CAPABILITIES.isScorerShadow">Scoreur ombre</b-badge>
                </div>
            </b-card>        
      </card-list-template>
    </collapse-panel>

    <collapse-panel lazy title="Représentants (juges & pilotes)" v-model="showDelegatesPanel">
      <form-field caption="Chef juge de la compétition">
        <b-form-input type="text" readonly :value="currentCompetition.CHIEF_JUDGE != null ? currentCompetition.CHIEF_JUDGE.name : ''" />
        <div class="error" v-if="currentCompetition.CHIEF_JUDGE == null">Le chef juge de la compétition n'a pas encore été défini !" </div>
      </form-field>
      <form-field caption="Chef juge pour la manche" :state="d_chief_judge == ''" invalidFeedback="Pas encore défini !">
        <b-form-input type="text" readonly :value="d_chief_judge"  />
        <div class="error" v-if="d_chief_judge == null || d_chief_judge == ''">Le chef juge de la manche n'a pas encore été défini !</div>
      </form-field>

      <form-field caption="Représentant des pilotes">
        <template #notice v-if="d_pilotsDelegate == null">
          Indiquez quel pilote s'est porté volontaire et a été désigné pour tenir le rôle de réprésentant des pilotes pour toute la durée de la compéition
        </template>
        <b-select v-model="d_pilotsDelegate" @change="pilotsDelegate_change" :disabled="currentRound.isBriefingCompleted">
          <option v-for="pilot in d_pilots" :key="pilot.id" :value="pilot.id">{{ pilot.name }}</option>
        </b-select>
      </form-field>    
    </collapse-panel>

    <collapse-panel lazy title="Format / Epreuves" v-model="showFormatPanel">
      <div v-if="currentRound.round_number==1">La compétition repose sur le règlement "<strong>{{ currentCompetition.REGULATION.name }}</strong>".</div>
      <div v-if="currentRound.round_number==1">Il est prévu de faire <strong>{{ currentCompetition.ROUNDS.length }}</strong> manche composées d'une ou plusieurs épreuves.</div>
      <div>Pour cette {{ currentRound.rank }} manche, il y aura :
         <ul>
          <li v-for="event in currentRound.RST_REF_EVENTS" :key="event.name + event.level">1 épreuve de type <strong>{{ event.EVENT.name }}</strong> ({{ event.LEVEL.name}})</li>
        </ul>
      </div>
    </collapse-panel>

    <collapse-panel v-if="d_levels_with_events_with_compulsories.length > 0" lazy title="Figures de précision">
      
        <div class="notice" v-if="d_levels_with_events_with_compulsories.length > 1">Choisissez un niveau puis l'épreuve et enfin une catégorie pour afficher les figures de précision qui seront à présenter lors de chaque épreuve en comportant</div>
        <div class="notice" v-else-if="d_levels_with_events_with_compulsories[0].eventsWithCompulsories.length > 1">Choisissez une épreuve puis une catégorie pour afficher les figures de précision qui seront à présenter lors de l'épreuve</div>
        <div class="notice" v-else-if="d_levels_with_events_with_compulsories[0].eventsWithCompulsories[0].categories.length > 1">Choisissez une catégorie pour afficher les figures de précision qui seront à présenter lors de l'épreuve</div>
        <levels :value="d_levels_with_events_with_compulsories" v-slot="levelSlotProps" hideSingleItemTitle>
            <events :value="levelSlotProps.level.eventsWithCompulsories" v-slot="eventSlotProps" hideSingleItemTitle>
              <!--<template name="single-item-title-postpend">&nbsp;-&nbsp;{{levelSlotProps.level.name}}</template>-->
              <categories iconSize="xsm" :value="eventSlotProps.event.categories" v-slot="categorySlotProps" hideSingleItemTitle>
                  <h5><!--<icon-category v-if="categorySlotProps.isSingle" :size="xsm" v-model="categorySlotProps.category.code"></icon-category>-->"{{ eventSlotProps.event.name}}" ({{ levelSlotProps.level.name}}) - {{ categorySlotProps.category.name }}</h5>
                <compulsories v-model="categorySlotProps.category.compulsories"></compulsories>
              </categories>
            </events>
        </levels>


    </collapse-panel>

    <collapse-panel lazy title="Ordres de passage" v-model="showCompetitorsOrderPanel">
      <div v-if="d_competitors_order_rule != null && d_competitors_order_rule != ''">
        <div>La règle retenue pour déterminer l'ordre de passage est "<strong>{{ d_competitors_order_rule }}</strong>".</div>

        <div v-if="d_levels.length > 1">
          <div class="notice">Choisissez un niveau puis une catégorie pour afficher les ordres de passage pour cette catégorie dans ce niveau</div>
          <levels v-model="d_levels" v-slot="levelSlotProps">
            <div v-if="levelSlotProps.level.categories.legnth > 1">
              <categories v-model="levelSlotProps.level.categories" v-slot="categorySlotProps" iconSize="xsm">
                <competitors v-model="categorySlotProps.category" includeLevelInTitle></competitors> 
              </categories>
            </div>
            <competitors v-else v-model="levelSlotProps.level.categories[0]" includeLevelInTitle>
            </competitors>
          </levels>
        </div>
        <div v-else>
          <div v-if="d_levels[0].categories.length > 1">
            <div class="notice">Choisissez une catégorie pour afficher les ordres de passage pour cette catégorie</div>
            <categories v-model="d_levels[0].categories" v-slot="categorySlotProps" icon-size="xsm">
              <competitors v-model="categorySlotProps.category">
              </competitors>
            </categories>
          </div>
          <competitors v-else v-model="d_levels[0].categories[0]">
          </competitors>
        </div>
      </div>
      <div v-else class="error">La règle à utiliser pour déterminer l'ordre de passage n'a pas encore été définie !</div>
    </collapse-panel>

    <collapse-panel lazy title="Présentation des terrains" v-model="showFieldsPanel" >
      <div v-if="d_fields.length > 1">
        <div class="notice">Si possible, indiquez sur quel terrain se dérouleront les différentes épreuves</div>
        <b-tabs lazy >
          <b-tab v-for="field in d_fields" :key="field.id" :title="'N° ' + field.field_number">
            <field-editor
              :value="field"
              showSummary
              :canSetHotState = "!currentRound.isBriefingCompleted"
              :isRemovable="false"
              @stageIn_change="fieldStageIn_change"
              @stageOut_change="fieldStageOut_change"
              @hotState_change="fieldHotState_change"
            />
          </b-tab>
        </b-tabs>
      </div>
      <div v-else-if="d_fields.length == 1">
        <field-editor
          :value="d_fields[0]"
          showSummary
          :canSetHotState="!currentRound.isBriefingCompleted"
          :isRemovable="false"
          @stageIn_change="fieldStageIn_change"
          @stageOut_change="fieldStageOut_change"
          @hotState_change="fieldHotState_change"
        />
      </div>
    </collapse-panel>

    <b-container fluid>
      <div style="display:flex; justify-content: stretch;">
        <b-button v-if="currentRound.isBriefingStarted && !currentRound.isStaeted" style="width: 30% !important;" variant="warning" @click="cancelBriefing"><b>ANNULER</b><br/><i style="font-size:0.9em">Briefing démarré par erreur</i></b-button>
        <b-button :variant="currentCompetition.PILOTS_DELEGATE == null ? 'outline-primary' : 'primary'" style="width:100% !important;" @click="completeBriefing" v-if="currentRound.isReadyToBriefing && !currentRound.isBriefingCompleted" :disabled="currentCompetition.PILOTS_DELEGATE == null"><b>TERMINER LE BRIEFING</b><br/>pour démarrer les épreuves de la manche</b-button>
      </div>
    </b-container>

  </div>
</template>

<script>
import { RST_ROUND, RST_FIELD, RST_LEVEL, /*RST_COMPETITION_STAFF,*/ RST_COMPETITOR, RST_EVENT, RST_EVENT_COMPETITOR } from "@/LocalDB";
import CollapsePanel from '../../../components/Panels/CollapsePanel.vue';
import FormField from '../../../components/Panels/FormField.vue';
//import DetailedStaffList from '@/components/DetailedStaffList.vue';
import { CompetitorExecOrderRuleEnum } from '@/Constants';
import SynchroHelper from "@/services/helpers/synchroHelper";

import RoundsConfigurationHelper from "@/services/helpers/roundsConfigurationHelper";
import FieldEditor from '@/components/fieldEditor.vue';
import CompetitionsConfigurationHelper from "@/services/helpers/competitionsConfigurationHelper";
import {  RST_DELEGATE, RST_EVENT_COMPULSORY } from "../../../LocalDB";
import Levels from "../components/Briefing/Levels.vue";
import Events from "../components/Briefing/Events.vue";
import Categories from "../components/Briefing/Categories.vue";
import Competitors from "../components/Briefing/Competitors.vue";
import Compulsories from "../components/Briefing/Compulsories.vue";
import ErrorsViewer from "../components/ErrorsViewer.vue";
import CardListTemplate from '@/components/Lists/CardListTemplate.vue';
import FederalNumber from '@/components/Panels/FederalNumber.vue';

export default {
  components: { CollapsePanel, FormField, ErrorsViewer, /*IconCategory,*/ FieldEditor, /*DetailedStaffList,*/ /*DetailedCompetitorsList,*/ Levels, Categories, Competitors, Events, Compulsories /*EventCompulsories*/, CardListTemplate, FederalNumber},
  data(){
    return {
      VERBOSE: false,
      showStaffPanel: true,
      showDelegatesPanel: false,
      showFormatPanel: false,
      showCompulsoriesPanel: false,
      showCompetitorsOrderPanel: false,
      showFieldsPanel: false,

      d_pilotsDelegate: null,

      d_competitors_order_rule: "",

      d_chief_judge: null,

      d_pilots: [],
      d_levels: [],
      d_fields: [],

      d_events_with_compulsories: [],
      d_levels_with_events_with_compulsories: [],

      //d_errors: [],
//      dSelectedLevel: null,
//      dSelectedCategory: null,
      mounting: false,
    };
  },

  computed:{
    ROUND(){ return this.currentRound; }
/*
    levels(){
      var ret = RoundsConfigurationHelper.ensureLevelsHavingEffectiveCompetitors(this.currentRound)
      .map(item => {
        item.text = item.LEVEL.name;
        item.value = item;
        return item;
      });
      return ret;
    },
    selectedLevel:{
      get(){ return (this.dSelectedLevel == null && this.levels.length == 1) ? this.levels[0] : this.dSelectedLevel },
      set(val){ this.dSelectedLevel = val; }
    },
    categories(){
      var ret = RoundsConfigurationHelper.ensureCategoriesHavingEffectiveCompetitors(this.selectedLevel)
      .map(item => {
        item.text = item.CATEGORY.name;
        item.value = item;
        return item;
      });
      return ret;
    },
    selectedCategory:{
      get(){ return (this.dSelectedCategory == null && this.categories.length == 1) ? this.categories[0] : this.dSelectedCategory},
      set(val){ this.dSelectedCategory = val; }
    },
    compulsories(){
      if(this.selectedLevel == null) return [];
      var ret = RST_ROUND_COMPULSORY.query()
                  .where("competition_id", this.selectedCategory.competition_id)
                  .where("round_number", this.selectedCategory.round_number)
                  .where("level", this.selectedCategory.level)
                  .where("category", this.selectedCategory.category)
                  .orderBy("order")
                  .get();
      return ret;
    },
    competitors(){
      if(this.selectedCategory == null) return [];

      var ret = this.selectedCategory.EFFECTIVE_COMPETITORS;
      if(this.VERBOSE) console.log('competitors avant tri', ret);
      //ret = RoundsConfigurationHelper.sortCompetitorsForExecution(ret, this.currentRound.competitors_order_rule);
      ret = RoundsConfigurationHelper.sortCompetitorsForExecution(ret, this.currentRound, this.selectedLevel.code, this.selectedCategory.code);
      return ret;
    },
    pilots(){
      var ret = RoundsConfigurationHelper.getPilotsDelegateCandidates(this.currentRound);
      return ret;
    },
    pilotsDelegate: {
      get(){ return null; },
      set(newValue){
        if(this.VERBOSE) console.log("pilotsDelegate (set)", newValue);
      }
    },
    fields(){
      return RST_FIELD.query().where("competition_id", this.currentCompetition_id).get();
    }*/
  },
  methods:{
    async refresh()
    {
      if(this.VERBOSE) console.log("refresh staff members", this.currentRound);
      this.d_round_number = this.currentRound_number;
      await this.showRefresher();
      //var R = RST_ROUND.query().where("competition_id", this.currentCompetition_id).where("round_number", this.currentRound_number).first();
      
      //this.d_errors = R.configurationErrors; 
      var pilots = [];
      this.d_levels_with_events_with_compulsories = RST_LEVEL.query()
                        .where("competition_id", this.currentCompetition.id)
                        .orderBy(l => l.LEVEL.displayOrder)
                        .get()
                        .map(lvl => {
                          var events = this.currentRound._RST_REF_EVENTS.where(e => e.level == lvl.level).where(e => e.EVENT.hasCompulsories).orderBy(e => e.EVENT.displayOrder).get().map(evt => {
                            if(this.VERBOSE) console.log('evt', evt);
                            var categories = evt.LEVEL._CATEGORIES
                              .where(c => c.effective_competitors_count > 0)
                              .orderBy(c => c.CATEGORY.displayOrder)
                              .get()
                              .map(cat => {
                                var compulsories = RST_EVENT_COMPULSORY.query()
                                                    .where("competition_id", this.currentRound.competition_id)
                                                    .where("round_number", this.currentRound.round_number)
                                                    .where("level", lvl.level)
                                                    .where("category", cat.category)
                                                    .where("event", evt.event)
                                                    .get();

                                cat.compulsories = compulsories;
                                cat.name = cat.CATEGORY.name;
                                cat.code = cat.category;
                                return cat;
                              });
                            return { ...evt, categories: categories, name: evt.name };
                          });
//                          if(events.length > 0) this.d_levels_with_events_with_compulsories++;
                          return { ...lvl, ...lvl.LEVEL, eventsWithCompulsories: events };
                        })
                        .filter(lvl => {
                          return lvl.eventsWithCompulsories != null && lvl.eventsWithCompulsories.length > 0
                        });
      this.d_levels = RST_LEVEL.query()
                        .where("competition_id", this.currentCompetition_id)
                        .orderBy(l => l.LEVEL.displayOrder)
                        .get()
                        .map(lvl => {
                          var categories = lvl._CATEGORIES
                            .where(c => c.effective_competitors_count > 0)
                            .orderBy(c => c.CATEGORY.displayOrder)
                            .get()
                            .map(cat => {
                            var allCompetitors = RST_COMPETITOR.query()
                              .where("competition_id", this.currentCompetition_id)
                              .where("level", lvl.level)
                              .where("category", cat.category)
                              .where(c => !c.isWithdrawn && !c.isAbsent && !c.isForfeited)
                              .get()
                              .map(c => { 
                                if(cat.CATEGORY.isIndividual && pilots.findIndex(p => p.id == c.COMPETITOR.people_id) < 0){
                                  pilots.push(c.COMPETITOR.PEOPLE)
                                } else if(!cat.CATEGORY.isIndividual){
                                  c.MEMBERS.forEach(m => {
                                    if(pilots.findIndex(p => p.id == m.people_id) < 0){
                                      pilots.push(m.PEOPLE);
                                    }
                                  })
                                }
                                return { ...c, ...c.COMPETITOR, MEMBERS: c.COMPETITOR.MEMBERS }
                              });
                            allCompetitors = RoundsConfigurationHelper.sortCompetitorsForExecution(allCompetitors,this.currentRound, lvl.level, cat.category);
                            this.d_competitors_order_rule = CompetitorExecOrderRuleEnum.getDisplayName(this.currentRound.competitors_order_rule);
                            /*switch(this.currentRound.competitors_order_rule){
                                case CompetitorExecOrderRuleEnum.Registration:
                                    this.d_competitors_order_rule = "Ordre d'inscription";
                                    //allCompetitors.sort((a,b) => this.compare(a.subscription_order , b.subscription_order) );
                                    break;
                                case CompetitorExecOrderRuleEnum.Alea:
                                    this.d_competitors_order_rule = "Ordre aléatoire";
                                    //allCompetitors.sort((a,b) => this.compare(a.random_order, b.random_order));
                                    break;
                                case CompetitorExecOrderRuleEnum.Alpha:
                                    this.d_competitors_order_rule = "Ordre alphabétique";
                                    //allCompetitors.sort((a,b) => this.compare(a.name, b.name, 'asc'));
                                    break;
                                case CompetitorExecOrderRuleEnum.AlphaReverse:
                                    this.d_competitors_order_rule = "Ordre alphabétique inversé";
                                    //allCompetitors.sort((a,b) => this.compare(a.name, b.name, 'desc'));
                                    break;
                                case CompetitorExecOrderRuleEnum.PrevRoundReverse:
                                    this.d_competitors_order_rule = "Ordre inverse du tour précédent";
                                    //console.log ('tri des compétiteurs par ordre "' + CompetitorExecOrderRuleEnum. PrevRoundReverse + "' à implémenter");
                                    break;
                                case CompetitorExecOrderRuleEnum.Rank:
                                    this.d_competitors_order_rule = "Classement provisoire";
                                    //console.log ('tri des compétiteurs par ordre "' + CompetitorExecOrderRuleEnum. Rank + "' à implémenter");
                                    break;
                                case CompetitorExecOrderRuleEnum.RankReverse:
                                    this.d_competitors_order_rule = "Classement provisoire inversé";
                                    //console.log ('tri des compétiteurs par ordre "' + CompetitorExecOrderRuleEnum. RankReverse + "' à implémenter");
                                    break;
                            }*/
                            return { ...cat, ...cat.CATEGORY, competitors: allCompetitors, levelName: lvl.name };
                          }); 

                          return { ...lvl, ...lvl.LEVEL, categories: categories };
                        });
                        if(this.VERBOSE) console.log("d_levels", this.d_levels, this.d_levels_with_events_with_compulsories, this.d_errorq);
      pilots.sort((a,b) => a.name.localeCompare(b.name));
      this.d_pilots = pilots;

      this.d_fields = RST_FIELD.query().where("competition_id", this.currentCompetition_id).orderBy("field_number").get();

      this.d_chief_judge = this.currentRound.CHIEF_JUDGE != null ? this.currentRound.CHIEF_JUDGE.name : '';

      this.d_events_with_compulsories = this.currentRound._RST_REF_EVENTS.where(e => e.EVENT.hasCompulsories).orderBy(e => e.EVENT.displayOrder).get();

      var delegate = RST_DELEGATE.query().where('competition_id', this.currentCompetition.id).where('delegate_type', 'C').first();
      this.d_pilotsDelegate = (delegate == null) ? null : delegate.people_id;

      this.hideRefresher();
    },
    async pilotsDelegate_change(newVal){
      if(this.mounting) return;
      await this.showSaver();
      var ok = true;
      if(this.d_pilotsDelegate != null)
        ok = CompetitionsConfigurationHelper.removeDelegateAsync(this.currentCompetition.id, this.d_pilotsDelegate);
      if(ok)
        ok = CompetitionsConfigurationHelper.addDelegateAsync(this.currentCompetition.id, newVal);
      this.toastAsyncSaved(ok, "Délégué de pilote mis à jour");
      this.hideSaver();
    },
 
    async fieldStageIn_change(field_number, newValue){
      await this.$showSaver();
      try{
        var result = await CompetitionsConfigurationHelper.setFieldStageInAsync(this.currentCompetition_id, field_number, newValue);
        this.toastAsyncSaved(result, "Entrée du terrain modifiée");
      }catch(error)
      {
        this.toastSaveFailure(error);
      }
      this.$hideSaver();
    },
    async fieldStageOut_change(field_number, newValue){
      await this.$showSaver();
      try{
        var result = await CompetitionsConfigurationHelper.setFieldStageOutAsync(this.currentCompetition_id, field_number, newValue);
        this.toastAsyncSaved(result, "Entrée du terrain modifiée");
      }catch(error)
      {
        this.toastSaveFailure(error);
      }
      this.$hideSaver();
    },
    async fieldHotState_change(field_number, newValue){
      await this.$showSaver();
      try{
        var result = await CompetitionsConfigurationHelper.setFieldHotStateAsync(this.currentCompetition_id, field_number, newValue);
        this.toastAsyncSaved(result, "Entrée du terrain modifiée");
      }catch(error)
      {
        this.toastSaveFailure(error);
      }
      this.$hideSaver();
    },
    async completeBriefing(){
      var operationIds = [];
      await this.showSaver();
      if(this.VERBOSE) console.log('completeBriefing', this.d_levels);
      this.d_levels.forEach(lvl => {
        lvl.categories.forEach(cat => {
          var o = 0;
          
          //var evtCompetitors = cat.EFFECTIVE_COMPETITORS;
          //evtCompetitors = RoundsConfigurationHelper.sortCompetitorsForExecution(evtCompetitors, this.currentRound, lvl.code, cat.code );
          //evtCompetitors.forEarch(comp => {
          cat.competitors.forEach(comp => {
            o++;
            if(this.VERBOSE) console.log("currentRound.RST_REF_EVENTS", this.currentRound.RST_REF_EVENTS);
            this.currentRound.RST_REF_EVENTS.forEach(ref_evt => {
              var evt = RST_EVENT.query()
                          .where("competition_id", this.currentCompetition_id)
                          .where("round_number", this.currentRound_number)
                          .where("level", lvl.level)
                          .where("category", cat.category)
                          .where("event", ref_evt.event)
                          .first();
              if(evt == null)
              {
                var newId = this.newGuid();
                evt = new RST_EVENT();
                evt.$id = newId
                evt.id = newId;
                evt.competition_id = this.currentCompetition_id;
                evt.round_number = this.currentRound_number;
                evt.level = lvl.level;
                evt.category = cat.category;
                evt.event = ref_evt.event;
                RST_EVENT.insert({data: evt});
                operationIds.push(SynchroHelper.LogOperation("INSERT", "RST_EVENT", null, evt));
              }
              var existingCompetitor = evt._COMPETITORS.where("competitor_id", comp.competitor_id).first();
              if(existingCompetitor == null){
                newId = this.newGuid();
                existingCompetitor = new RST_EVENT_COMPETITOR();
                existingCompetitor.$id = newId;
                existingCompetitor.id = newId;
                existingCompetitor.competition_id = evt.competition_id;
                existingCompetitor.competitor_id = comp.competitor_id;
                existingCompetitor.level = evt.level;
                existingCompetitor.category = evt.category;
                existingCompetitor.round_number = evt.round_number;
                existingCompetitor.event = evt.event;
                existingCompetitor.predicted_order = o;
                existingCompetitor.order = o;
                existingCompetitor.execution_status = RST_EVENT_COMPETITOR.ExecutionStatus_Waiting; // W=waiting, R=running, C=completed
                existingCompetitor.isAbsent = comp.isAbsent;
                existingCompetitor.isForfeited = comp.isForfeited;
                //existingCompetitor.isFederal = comp.isFederal;
                RST_EVENT_COMPETITOR.insert({data: existingCompetitor});
                if(this.VERBOSE) console.log('Inserting competititor', `${existingCompetitor.name} - ${existingCompetitor.event} : ${existingCompetitor.predicted_order}`);
                operationIds.push(SynchroHelper.LogOperation("INSERT", "RST_EVENT_COMPETITOR", null, existingCompetitor));
              } else {
                var data = {
                  predicted_order: o,
                  order: o,
                  effective_order: 0,
                  execution_status: RST_EVENT_COMPETITOR.ExecutionStatus_Waiting,
                  isAbsent: comp.isAbsent,
                  isForfeited: comp.isForfeited,
                  //isFederal: comp.isFederal,
                }
                RST_EVENT_COMPETITOR.update({ where: existingCompetitor.id, data: data });
                if(this.VERBOSE) console.log('Updating RST_EVENT_COMPETITOR', `${existingCompetitor.name} - ${existingCompetitor.event} : ${existingCompetitor.predicted_order}`);
                operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_EVENT_COMPETITOR", existingCompetitor.id, data, "PREPARE_FOR_RUN" ));
              }
            });
          });
        });
      });
      var roundChanges = {
        isBriefingStarted: true,
        isBriefingCompleted: true,
        date: this.currentRound.date || new Date().toISOString(),
      }
      RST_ROUND.update({ where: this.currentRound.id, data: roundChanges});
      operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_ROUND", this.currentRound.id, roundChanges, "BRIEFING_STATUSES"));
      if(operationIds.length > 0)
      {
        var result = await SynchroHelper.TryToSynchronizeAsync(operationIds);
        this.toastAsyncSaved(result,"Les épreuves ont été préparées et peuvent maintenant être réalisées.");
        this.hideSaver();
        this.$router.push({name: 'CompetitionEditOrRun'});
      }
    },
    async cancelBriefing(){
      var operationIds = [];
      await this.showSaver();
      var roundChanges = {
        isBriefingStarted: false,
        isBriefingCompleted: false,
        date: null,
      }
      RST_ROUND.update({ where: this.currentRound.id, data: roundChanges});
      operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_ROUND", this.currentRound.id, roundChanges, "BRIEFING_STATUSES"));
      var result = await SynchroHelper.TryToSynchronizeAsync(operationIds);
      this.toastAsyncSaved(result,"Briefing annulé.");
      this.hideSaver();
      this.$router.push({name: 'CompetitionEditOrRun'});
    }
  },
  async mounted(){
    this.d_pilotsDelegate = this.currentCompetition.PILOTS_DELEGATE != null ? this.currentCompetition.PILOTS_DELEGATE.people_id : null;
    this.mounting = true;
    this.$hideLoader();
    await this.refresh();
    this.mounting = false;
  }
}
</script>

<style scoped>
    .badge.capability{
        margin-right: 10px;
        padding: 5px;
        border:solid 2px green;
        color: rgb(1, 68, 1);
    }

    .badge.capability-judge{
        background-color: rgb(244, 244, 158);
    }
    .badge.capability-field-director{
        background-color: rgb(122, 211, 243);
    }    
    .badge.capability-scorer{
        background-color: pink;
    }    
    .badge.capability.capability-shadow{
        border:solid 2px orange;
        color:rgb(78, 51, 1);
    }
    .badge.capability-shadow.capability-judge{
        background-color: rgb(247, 247, 199);
    }    
    .badge.capability-shadow.capability-field-director{
        background-color: rgb(176, 227, 246);
    }    
    .badge.capability-shadow.badge.capability-scorer{
        background-color: rgb(253, 229, 233);
    }   
    .card.small .card-body { padding: 0.4rem !important;}
    .card.small .badge{ margin-left: 0.2rem !important; margin-right: 0.2rem !important; padding: 0.2rem !important;}
</style>
<style scoped>
  label.btn{ margin-left: 5px; margin-right: 5px;}
  a.nav-link.active{ background-color: var(--ffvlOrangePicto) !important;}
  a.nav-link { padding: 0.1rem !important;}

  .error{ font-size: 1.1rem; color: var(--ffvlDanger); font-weight: bold; font-style: italic; display: block;}

  .staff-member-name { font-size: 1.1rem; }
</style>
